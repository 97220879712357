import React, { useState, useEffect, useContext } from 'react';
import { Box, Keyboard, TextInput, Text, MaskedInput, Image, Button } from 'grommet'
import Logo2 from '../assets/img/logo2.png'
import Logo3 from '../assets/img/logo3.png'
import { View, Hide } from 'grommet-icons'
import { auth } from 'firebase/app'
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import BG from '../assets/img/bgn.jpg'

const Login = () => {


    const history = useHistory();
    const { authUser, } = useContext(UserContext)

    const [reveal, setReveal] = useState(false)
    const [logging, setLogging] = useState(false)
    const [errorLogin, setErrorLogin] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    var user = auth().currentUser;

    useEffect(() => {

        if (user) {
            // User is signed in.
            history.push("/admin")
        } else {
            // No user is signed in.
        }
        if (authUser) {
            console.log(authUser)
        }
    }, [])


    const login = () => {

        setLogging(true)
        setErrorLogin(false)
        auth().signInWithEmailAndPassword(email, password).then(re => {
            history.push('/admin')
            setLogging(false)
        }).catch((error) => {
            setLogging(false)
            setErrorLogin(true)
        });
    }


    return (
        <Box flex="grow" background={"whitesmoke"} align="center" justify="center">
            <Box background="white" elevation="large" gap="medium" width="medium" pad="medium" round="15px">
                <Box align="center">
                    <Box width="70%" height="80px">
                        <Image fit="contain" src={Logo3} />
                    </Box>
                </Box>
                {errorLogin && <Box animation="fadeIn" align="center" background="status-error" round="small" pad="small">
                    <Text color="white" >Usuario o contraseña incorrectos.</Text>
                </Box>}
                <Keyboard onEnter={login}>
                    <Box width="medium" gap="medium">
                        <MaskedInput
                            mask={[
                                {
                                    regexp: /^[\w\-_.]+$/,
                                    placeholder: "nombre"
                                },
                                { fixed: "@" },
                                {
                                    regexp: /^[\w\-_.]+$/,
                                    placeholder: "email"
                                },
                                { fixed: "." },
                                {
                                    regexp: /^[\w]+$/,
                                    placeholder: "com"
                                }
                            ]}
                            name="email"
                            size="medium"
                            type="email"
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                        />
                        <Box width="medium" direction="row" align="center" round="4px" border>
                            <TextInput
                                plain
                                value={password}
                                onChange={(ev) => setPassword(ev.target.value)}
                                placeholder="Contraseña"
                                type={reveal ? "text" : "password"}
                                style={{ fontSize: 16 }}
                            />
                            <Button
                                icon={reveal ? <View size="medium" /> : <Hide size="medium" />}
                                onClick={() => setReveal(!reveal)}
                            />
                        </Box>
                        <Button color="brand" disabled={logging} label={logging ? "Accediendo" : "Acceder"} onClick={login} primary />
                    </Box>
                </Keyboard>
            </Box>
        </Box>
    );
}

export default Login;