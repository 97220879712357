import React from 'react'
import { Footer as FooterGrommet, Text, Anchor } from 'grommet'
import { useLocation } from 'react-router-dom'

const Footer = ({ showMenu }) => {

    const { pathname } = useLocation()
    
    return (
        <FooterGrommet
            // style={{ position: "absolute", bottom: 0, width: "100%" }} 
            style={{ display: showMenu ? "flex" : "none" }}
            height="50px" background={"#444d58"} pad={{ horizontal: "medium" }}>
            <Text color="white" size="small">&reg; 2020 - Powered by novanet - <Text size="small" style={{ opacity: .5 }}>v2.0.3</Text></Text>
            <Anchor size="small" label="Soporte" color="white" target="_blank" href="https://www.novanet.es" />
        </FooterGrommet>
    )
}

export default Footer