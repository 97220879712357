import React, { useState, useContext, useRef } from 'react';
import { Box, Heading, Layer, TextInput, TextArea, Text, Button, MaskedInput, DropButton, Calendar } from 'grommet';
import { firestore, storage } from 'firebase/app'
import * as Icon from 'grommet-icons'
import moment from 'moment'
import UserContext from '../context/UserContext';
import defImg from '../assets/img/default.jpg'
import Loader from 'react-loader-spinner'
import img from '../assets/img/default.jpg'


const NewRoomModal = ({ data, closeCallback }) => {


    const userID = useContext(UserContext)
    const fileRef = useRef()

    const initForm = {
        title: "",
        description: "",
        participants: "",
        starts: "",
        ends: "",
        contact: "",
        email: "",
        organization: "",
    }

    const [newRoomForm, setNewRoomForm] = useState(data ? data : initForm)
    const [displayStarts, setDisplayStarts] = useState("")
    const [displayEnds, setDisplayEnds] = useState("")
    const [schedule, openSchedule] = useState(false)
    const [imageURL, setImageURL] = useState(img)
    const [file, setFile] = useState(null)
    const [loading, setLoading] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)

    const createRoom = async () => {
        setLoading(true)
        const startTime = moment(moment(newRoomForm.date).format("YYYY-MM-DD") + "T" + newRoomForm.starts)
        const endTime = moment(moment(newRoomForm.date).format("YYYY-MM-DD") + "T" + newRoomForm.ends)
        var duration = moment.duration(endTime.diff(startTime))

        if (newRoomForm.date === "") {
            alert("Introduce la fecha de la reunión")
            setLoading(false)
            return
        } else if (newRoomForm.starts === "") {
            alert("Introduce la hora de inicio de la reunión")
            setLoading(false)
            return
        } else if (newRoomForm.ends === "") {
            alert("Introduce la hora de finalización de la reunión")
            setLoading(false)
            return
        } else if (startTime < moment()) {
            alert("La hora de inicio ya ha pasado")
            setLoading(false)
            return
        } else if (duration.asHours() > 2) {
            // alert("La duración máxima de una reunión puede ser 2 horas")
            // return
        } else if (duration < 0) {
            setLoading(false)
            alert("La hora de inicio de la reunión no puede ser menor que la final")
            return
        }
        // else if (newRoomForm.private && newRoomForm.password === "") {
        //     alert("Las reuniones privadas requieren introducir una contraseña")
        //     return
        // }


        const fileToUpload = file ? storage().ref().child(`rooms/images/${newRoomForm.title}-${new Date()}-roomImage.jpg`) : null
        const roomImage = fileToUpload ? await fileToUpload.put(file).then((res) => res.ref.getDownloadURL()) : img

        const variables = {

            title: newRoomForm.title || "",
            description: newRoomForm.description || "",
            contact: newRoomForm.contact || "",
            email: newRoomForm.email || "",
            organization: newRoomForm.organization || "",
            participants: newRoomForm.participants !== "" ? parseInt(newRoomForm.participants) : 20,
            starts: startTime.toDate(),
            ends: endTime.toDate(),
            createdAt: firestore.FieldValue.serverTimestamp(),
            image: roomImage
        }

        firestore().collection("roomrequests").add(variables).then(res => {
            setShowSuccess(true)
            setNewRoomForm(initForm)
            setLoading(false)
        })
    }


    return (
        <Layer
            full="vertical"
            position="left"
            responsive={false}
            onEsc={() => closeCallback()}
            onClickOutside={() => closeCallback()}
        >
            <Box width="medium">


                <Box pad={{ right: "small", vertical: "10px" }} align="center" direction="row" background="brand">
                    <Box direction="row" flex align="center" gap="small">
                        <Button hoverIndicator icon={<Icon.Close />} onClick={() => closeCallback()} />
                        <Heading level="4" margin="none">Solicitar Sala</Heading>
                    </Box>
                    {loading ?
                        <Loader type="Puff" color="white" height={45} width={45} />
                        :
                        <Button primary size="small" style={{ borderRadius: 5 }} color="light-1" disabled={newRoomForm.title === "" || newRoomForm.email === ""} label="Solicitar" onClick={() => createRoom()} />
                    }
                </Box>
                <Box overflow={{ vertical: "scroll", horizontal: "hidden" }} >
                    {showSuccess ?
                        <Box align="center" pad="medium" gap="medium">
                            <Text>Tu solicitud ha sido procesada con éxito. En breves recibirás la confirmación y las claves de acceso de tu sala. Muchas gracias</Text>
                            <Button primary onClick={()=> {
                                setShowSuccess(false)
                                closeCallback()
                            }} label="Cerrar"
                                />
                        </Box>
                        :
                        <Box pad="medium" gap="medium" flex="grow">
                            <Box gap="small">
                                <Text size="small">Nombre de la Sala</Text>
                                <TextInput value={newRoomForm.title} placeholder="Nombre" onChange={(ev) => setNewRoomForm({ ...newRoomForm, title: ev.target.value })} />
                            </Box>

                            <Box gap="small">
                                <Text size="small">Asunto de la Reunión</Text>
                                <Box height={{ min: "150px" }}>
                                    <TextArea style={{ minHeight: 150 }} value={newRoomForm.description} fill={true} resize="vertical" placeholder="Escribe aquí..." onChange={(ev) => setNewRoomForm({ ...newRoomForm, description: ev.target.value })} />
                                </Box>
                            </Box>

                            <Box gap="small">
                                <Text size="small">Nombre de la Persona de Contacto</Text>
                                <TextInput value={newRoomForm.contact} placeholder="Nombre y Apellidos..." onChange={(ev) => setNewRoomForm({ ...newRoomForm, contact: ev.target.value })} />
                            </Box>

                            <Box gap="small">
                                <Text size="small">Email de Contacto (Las notificaciones y detalles de la sala se enviarán a esta dirección)</Text>
                                <TextInput value={newRoomForm.email} placeholder="Email..." onChange={(ev) => setNewRoomForm({ ...newRoomForm, email: ev.target.value })} />
                            </Box>

                            <Box gap="small">
                                <Text size="small">Club/Organización</Text>
                                <TextInput value={newRoomForm.organization} placeholder="Escribe Aquí..." onChange={(ev) => setNewRoomForm({ ...newRoomForm, organization: ev.target.value })} />
                            </Box>

                            <Box gap="small">
                                <Text size="small">Número de Participantes</Text>
                                <TextInput type="number" value={newRoomForm.participants} placeholder="Número" onChange={(ev) => setNewRoomForm({ ...newRoomForm, participants: ev.target.value })} />
                            </Box>
                            {/* 
                        {newRoomForm.participants > 20 &&
                            <Box gap="small">
                                <Box align="center" justify="center" gap="medium" direction="row" background="light-2" pad={{ horizontal: "medium", vertical: "small" }} round="5px">
                                    <Icon.Alert />
                                    <Text>Cuando el número de participantes supera los 20 se deberá emplear una conferencia de Zoom o cualquier otro servicio.</Text>
                                </Box>
                                <Box gap="small">
                                    <Text size="small">Enlace de Zoom</Text>

                                    <Box direction="row" gap="small" align="center">
                                        <TextInput value={newRoomForm.zoom} placeholder="Enlace" onChange={(ev) => setNewRoomForm({ ...newRoomForm, zoom: ev.target.value })} />
                                        <Button icon={<Icon.Link />} target="_blank" href={newRoomForm.zoom && newRoomForm.zoom.includes("http") ? newRoomForm.zoom : `https://${newRoomForm.zoom}`} />
                                    </Box>
                                </Box>

                            </Box>
                        } */}

                            <Box gap="small">
                                <Text size="small">Día de la reunión</Text>
                                <DropButton
                                    open={schedule}
                                    onClose={() => openSchedule(false)}
                                    onOpen={() => openSchedule(true)}
                                    dropContent={
                                        <Calendar
                                            animate={false}
                                            size="small"
                                            daysOfWeek={true}
                                            firstDayOfWeek={1}
                                            locale="es-ES"
                                            bounds={[moment().format(), moment().add('years', 2).format()]}
                                            date={newRoomForm.date || moment().format()}
                                            onSelect={(ev) => {
                                                console.log(ev)
                                                setNewRoomForm({ ...newRoomForm, date: ev })
                                                openSchedule(false)
                                            }}
                                            showAdjacentDays={false}
                                        />
                                    }
                                >
                                    <Box direction="row" gap="small" align="center">
                                        <TextInput
                                            // type="date"
                                            placeholder="Selecciona un día"
                                            value={newRoomForm.date
                                                ? `${new Date(newRoomForm.date).toLocaleDateString()}`
                                                : ""} />
                                        <Box pad="small">
                                            <Icon.Schedule />
                                        </Box>
                                    </Box>
                                </DropButton>
                            </Box>


                            <Box direction="row" gap="small">
                                <Box gap="small" flex >
                                    <Text size="small">Inicio (24h)</Text>
                                    <MaskedInput
                                        mask={[
                                            {
                                                length: [1, 2],
                                                regexp: /^[0-1][0-9]|2[0-3]$|^[0-9]$/,
                                                placeholder: "hh"
                                            },
                                            { fixed: ":" },
                                            {
                                                length: 2,
                                                regexp: /^[0-5][0-9]$|^[0-9]$/,
                                                placeholder: "mm"
                                            },
                                        ]}
                                        value={displayStarts}
                                        name="maskedInput"
                                        onChange={event => {
                                            setDisplayStarts(event.target.value)
                                            setNewRoomForm({ ...newRoomForm, starts: moment(event.target.value, ["h:mm A"]).format("HH:mm") })
                                        }}
                                    />
                                </Box>


                                <Box gap="small" flex>
                                    <Text size="small">Fin (24h)</Text>
                                    <MaskedInput
                                        mask={[
                                            {
                                                length: [1, 2],
                                                regexp: /^[0-1][0-9]|2[0-3]$|^[0-9]$/,
                                                placeholder: "hh"
                                            },
                                            { fixed: ":" },
                                            {
                                                length: 2,
                                                regexp: /^[0-5][0-9]$|^[0-9]$/,
                                                placeholder: "mm"
                                            },
                                        ]}
                                        value={displayEnds}
                                        name="maskedInput"
                                        onChange={event => {
                                            setDisplayEnds(event.target.value)
                                            setNewRoomForm({ ...newRoomForm, ends: moment(event.target.value, ["h:mm A"]).format("HH:mm") })
                                        }}
                                    />
                                </Box>
                            </Box>

                            <Box gap="small">
                                <Text size="small">Imagen de la sala</Text>
                                <Box round="7px">
                                    <Box background={`url(${imageURL})`} flex="grow" pad="small" align="start" height="250px" round="small">
                                        <Button size="small" style={{ borderRadius: 7 }} primary label="Cambiar Imagen" onClick={() => fileRef.current.click()} />
                                        <input type="file" ref={fileRef} onChange={e => {
                                            setFile(e.target.files[0])
                                            setImageURL(URL.createObjectURL(e.target.files[0]))
                                        }}
                                            style={{ display: "none" }} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    }
                </Box>

            </Box>
        </Layer>
    );
}

export default NewRoomModal;