import React, { useEffect, useState, useRef, useContext } from 'react';
import { Box, Text, Button, Heading, Layer, TextInput, AccordionPanel, Accordion, Keyboard, ResponsiveContext, DropButton, Paragraph, CheckBox } from 'grommet';
import { OpenVidu } from 'openvidu-browser'
import * as Icon from 'grommet-icons'
import VideoComponent from './VideoComponent';
import SessionTimer from './SessionTimer';
import axios from 'axios';
import MenuContext from '../context/MenuContext';
import useFullscreenStatus from '../utils/useFullScreen'
import { isMobile, isSafari } from 'react-device-detect';
import moment from 'moment'
import ReactPlayer from 'react-player'
import ReactTooltip from "react-tooltip";


const width = window.innerWidth
const height = window.innerHeight

const API = "https://europe-west1-duo-city.cloudfunctions.net"

var ua = window.navigator.userAgent;
var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
var webkit = !!ua.match(/WebKit/i);
var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

const VideoRoom = ({ token, closeSessionCallback, clientData, id, data, password }) => {


    const size = useContext(ResponsiveContext)

    const maximizableElement = useRef()
    const [isFullscreen, setIsFullscreen] = useFullscreenStatus(maximizableElement)

    const OV = new OpenVidu();

    const currentPublisher = useRef(undefined)
    const userMicrophone = useRef(true)
    const userVideo = useRef(true)
    const chatOpen = useRef(false)
    const newMessages = useRef(0)
    const messagesContainer = useRef()
    const handsCounter = useRef([])
    const lastSpoken = useRef(moment())
    const videoPlayerRef = useRef()
    const shareButtonRef = useRef()
    const videoPlayerStatus = useRef({ status: false, link: "", time: 0, show: true, playing: false, seek: null, duration: 0, position: 0 })

    // SESIONES ABIERTAS
    const [session, setSession] = useState(OV.initSession())

    // STREAMS
    const [subscribers, setSubscribers] = useState([])
    const [streamSelected, setStreamSelected] = useState(undefined)
    const [mainStreamManager, setMainStreamManager] = useState(undefined)
    const [publisher, setPublisher] = useState(undefined)
    const [hands, setHands] = useState([])
    const [messages, setMessages] = useState([])
    const [message, setMessage] = useState("")
    const [messagesCounter, setMessagesCounter] = useState(0)
    const [videoPlayer, setVideoPlayer] = useState({ status: false, link: "", time: 0, show: true, playing: false, seek: null, duration: 0, position: 0 })

    // ESTADOS PARA CONTROLAR VISUALIZACION DE QUE ESTA ACTIVO Y QUE NO
    const [chat, setChat] = useState(false)
    const [contextVideo, setContextVideo] = useState(false)
    const [microphone, setMicrophone] = useState(true)
    const [video, setVideo] = useState(true)
    const [handRaised, setHandRaised] = useState(false)
    const [screenShare, setScreenShare] = useState(false)
    const [personalScreenShare, setPersonalScreenShare] = useState(false)
    const [visualization, setVisualization] = useState("FEATURED") //FEATURED O GRID, METER  VERTICAL A FUTURO
    const [speakerMode, setSpeakerMode] = useState(false)
    const [page, setPage] = useState(1)
    const [people, countPeople] = useState([])
    const [closing, setClosing] = useState(false)
    const [compatible, setCompatible] = useState(true)

    //ADMIN ESTADOS
    const [adminMenu, setAdminMenu] = useState(false)
    const [closeSession, setCloseSession] = useState(false)
    const [fullScreenElement, setFullScreenElement] = useState(null)
    const [linkCoppied, setLinkCoppied] = useState(false)


    const { menuFunctions } = useContext(MenuContext)

    useEffect(() => {

        OV.enableProdMode()

        !OV.checkSystemRequirements() && setCompatible(false)

        menuFunctions.showHide(false)
        initMainSession()
        return () => {
            session.disconnect()
            menuFunctions.showHide(true)
        }
    }, [])

    useEffect(() => {
        subscribers.length === 0 && setVisualization("FEATURED")
        mainStreamManager && mainStreamManager.stream && mainStreamManager.stream.getMediaStream() === undefined && setMainStreamManager(session.openvidu.role === "SUBSCRIBER" ? subscribers[0] : publisher)
    }, [subscribers])

    const initMainSession = () => {

        session.leave(true, (event) => {
            closeSessionCallback()
        })

        session.on('connectionCreated', (event) => {
            session.openvidu.role === "MODERATOR" && getRoomInfo()
        });

        session.on('connectionDestroyed', (event) => {

            session.openvidu.role === "MODERATOR" && getRoomInfo()


        });

        session.on('streamCreated', (event) => {
            // Subscribe to the Stream to receive it. Second parameter is undefined
            var sub = session.subscribe(event.stream, undefined)
            var subs = subscribers;
            subs.push(sub);
            console.log(sub)
            setSubscribers([...subs])
        });

        session.on('streamDestroyed', (event) => {
            const subs = subscribers
            let index = subs.indexOf(event.stream.streamManager, 0);
            if (index > -1) {
                subs.splice(index, 1);
                setSubscribers([...subs])
            }
        });

        session.on('publisherStartSpeaking', (event) => {

            const now = moment()
            if (now.diff(lastSpoken.current, 'seconds') > 3 && !screenShare) {
                setStreamSelected(subscribers.findIndex(el => el.stream.streamId === event.streamId))
                setMainStreamManager(subscribers.filter(el => el.stream.streamId === event.streamId)[0])
                visualization === "GRID" && videosPerPage && setPage(2)
                lastSpoken.current = moment()
            }
        })

        session.on('publisherStopSpeaking', (event) => {
            // console.log(event.streamId, " HA PARADO DE HABLAR")
        })

        session.on('signal:toogleAudio', (event) => {
            userMicrophone.current && currentPublisher.current.openvidu.role !== "MODERATOR" && switchMic()
        })

        session.on('signal:toogleVideo', (event) => {
            userVideo.current && currentPublisher.current.openvidu.role !== "MODERATOR" && switchVideo()
        })

        session.on('signal:raiseHand', (event) => {
            // currentPublisher.current.openvidu.role !== "MODERATOR" && switchVideo()
            if (handsCounter.current.includes(event.data)) {
                event.data === session.openvidu.session.connection.connectionId && setHandRaised(false)
                handsCounter.current = [...handsCounter.current.filter(m => m !== event.data)]
                setHands([...handsCounter.current.filter(m => m !== event.data)])
            } else {
                handsCounter.current.push(event.data)
                setHands([...handsCounter.current])
            }
        })

        session.on('signal:sendVideo', (event) => {

            const data = JSON.parse(event.data)
            const d = videoPlayerStatus.current
            const time = videoPlayerRef.current && videoPlayerRef.current.getCurrentTime()
            data.seek !== undefined && videoPlayerRef.current.seekTo(data.seek + time, 'seconds')
            setVisualization("FEATURED")
            setVideoPlayer({ ...d, ...data })
            videoPlayerStatus.current = { ...d, ...data }
            setTimeout(() => {
                videoPlayerRef.current && data.position !== undefined && videoPlayerRef.current.seekTo(data.position, 'seconds')
            }, 100)

        })
        session.on('signal:screenSharing', (event) => {
            const status = JSON.parse(event.data)
            if (status) {
                const subSharing = subscribers.filter(el => el.stream.connection.connectionId === event.from.connectionId)[0]
                const subSharingIndex = subscribers.findIndex(el => el.stream.connection.connectionId === event.from.connectionId)[0]
                subSharing && setMainStreamManager(subSharing)
                subSharing && setStreamSelected(subSharingIndex)
                setVisualization("FEATURED")
                setScreenShare(true)
            } else {
                setScreenShare(false)
            }
        })

        session.on('signal:userChanged', (event) => {
            const subs = subscribers
            subs.map(user => {
                if (user.stream.connection.connectionId === event.from.connectionId) {
                    var data = JSON.parse(event.data);
                    if (data.isAudioActive !== undefined) {
                        user.setAudioActive(data.isAudioActive);
                    }
                    if (data.isVideoActive !== undefined) {
                        user.setVideoActive(data.isVideoActive);
                    }
                    if (data.nickname !== undefined) {
                        user.setNickname(data.nickname);
                    }
                    if (data.isScreenShareActive !== undefined) {
                        user.setScreenShareActive(data.isScreenShareActive);
                    }
                }
            })
            setSubscribers([...subs])
        })

        session.on("sessionDisconnected", (event) => {
            closeSessionCallback()
        })

        session.on('signal:chat', (event) => {
            var data = JSON.parse(event.data);
            var messageList = messages;
            messageList.push({ connectionId: event.from.connectionId, nickname: data.nickname, team: data.team, message: data.message });
            setTimeout(() => {
                if (!chatOpen.current) {
                    newMessages.current = newMessages.current + 1
                }
                setMessagesCounter(newMessages.current)
                scrollToBottom()
            }, 50);
            setMessages([...messageList])
        });

        // --- 4) Connect to the session with a valid user token ---
        session.connect(token, { clientData: clientData }).then(() => {
            session.openvidu.role !== "SUBSCRIBER" && activateCam()
            session.openvidu.role === "SUBSCRIBER" && setMainStreamManager(subscribers[0])
        }).catch((error) => {
            console.log('There was an error connecting to the session:', error.code, error.message);
        });

    }

    const closeRoom = () => {
        setClosing(true)
        axios.post(API + "/api/closeSession", {
            method: 'POST',
            body: JSON.stringify({ sessionID: id, password: parseInt(password) })
        }).then((response) => {
            // console.log('ROOOM INFO', response)
            people.filter(el => el.connectionId !== session.connection.connectionId).map(sub => session.forceDisconnect(sub))
            leaveSession()
            setClosing(false)
        }).catch((error) => {
            // LOS 403 Y 404 SE CONTROLAN AQUI
            console.log(error.response)
        })

    }


    const leaveSession = () => {
        session.openvidu.role !== "SUBSCRIBER" && session.unpublish(publisher)
        closeSessionCallback()
    }


    const switchMic = () => {
        const pub = currentPublisher.current
        pub.publishAudio(!pub.stream.audioActive);
        session.signal({ data: pub.stream.audioActive ? "true" : "false", to: [], type: "userChanged" })
        setMicrophone(pub.stream.audioActive)
    }

    // ACTIVATES OR DEACTIVATES WEBCAM OF THE USER
    const switchVideo = () => {
        const pub = currentPublisher.current
        pub.publishVideo(!pub.stream.videoActive)
        session.signal({ data: pub.stream.videoActive ? "true" : "false", to: [], type: "userChanged" })
        setVideo(pub.stream.videoActive)
    }

    // CHANGES THE MAIN VIDEO ON FEATURED MODE
    const changeMainVideo = (stream, i) => {
        if (i === undefined) {
            session.openvidu.role === "SUBSCRIBER" ? setMainStreamManager(subscribers[0]) : setMainStreamManager(publisher)
            setStreamSelected(session.openvidu.role === "SUBSCRIBER" ? 0 : undefined)
        } else if (mainStreamManager !== stream && stream !== undefined) {
            setMainStreamManager(stream)
            setStreamSelected(i)
        } else {
            session.openvidu.role === "SUBSCRIBER" ? setMainStreamManager(subscribers[0]) : setMainStreamManager(publisher)
            setStreamSelected(session.openvidu.role === "SUBSCRIBER" ? 0 : undefined)
        }
    }

    const toogleChat = () => {
        chatOpen.current = !chatOpen.current
        newMessages.current = 0
        setChat(!chat)
        setMessagesCounter(0)
        scrollToBottom()
    }

    const scrollToBottom = () => {
        setTimeout(() => {
            // messagesContainer.scrollTop = 300
            try {
                messagesContainer.current.scrollTop = messagesContainer.current.scrollHeight
                //     // _this3.chatScroll.current.scrollTop = _this3.chatScroll.current.scrollHeight;
            } catch (err) { }
        }, 100)
    }

    const sendMessage = () => {
        const m = message.replace(/ +(?= )/g, '')
        var data = { message: m, nickname: clientData.userName, team: clientData.userTeam, streamId: publisher.stream.streamId };
        session.signal({ data: JSON.stringify(data), type: "chat" })
        setMessage("")
        scrollToBottom()
    }

    const changeVisualization = () => {
        if (visualization === "GRID") {
            setVisualization("FEATURED")
        } else {
            setVisualization("GRID")
            // videosPerPage > page * videosPerPage && 
            setPage(1)
        }
    }

    // ENVIA UNA SEÑAL A UN SUBSCRITOR QUE AL RECIBIRLA MUTEA EL VIDEO
    const muteVideoSubscriber = (user, status) => {
        if (status === false) {
            session.signal({ data: false, to: [user.stream.connection], type: "toogleVideo" })
        } else {
            session.signal({ data: !user.stream.videoActive, to: [user.stream.connection], type: "toogleVideo" })
        }
    }

    // ENVIA UNA SEÑAL A UN SUBSCRITOR QUE AL RECIBIRLA MUTEA EL AUDIO
    const muteAudioSubscriber = (user, status) => {
        if (status === false) {
            session.signal({ data: false, to: [user.stream.connection], type: "toogleAudio" })
        } else {
            session.signal({ data: !user.stream.audioActive, to: [user.stream.connection], type: "toogleAudio" })
        }
    }

    const kickOutUser = (stream) => {
        const userDB = stream.stream.connection.data && JSON.parse(stream.stream.connection.data).clientData.tok
        removeUser(userDB)
        session.forceDisconnect(stream.stream.connection)
    }

    const kickViewer = (stream) => {
        const userDB = JSON.parse(stream.clientData).clientData.tok
        removeUser(userDB)
        session.forceDisconnect(stream)
    }

    const unMuteSubscriber = (stream) => {
        stream.subscribeToAudio(true);  // true to unmute the audio track, false to mute it
        stream.subscribeToVideo(true);
    }

    const activateCam = () => {

        OV.getDevices().then((dev) => {
            // console.log(dev)
            // var track = media.getVideoTracks()[0];
            // Init a publisher passing undefined as targetElement (we don't want OpenVidu to insert a video
            // element: we will manage it on our own) and with the desired properties
            let pub = OV.initPublisher(undefined, {
                audioSource: undefined, // The source of audio. If undefined default microphone
                videoSource: undefined, // The source of video. If undefined default webcam
                publishAudio: microphone, // Whether you want to start publishing with your audio unmuted or not
                publishVideo: video, // Whether you want to start publishing with your video enabled or not
                resolution: `450x300`, // The resolution of your video
                frameRate: 24, // The frame rate of your video
                insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
                mirror: false, // Whether to mirror your local video or not
            });
            // Publish your stream
            session.publish(pub);
            // Set the main video in the page to display our webcam and store our Publisher
            currentPublisher.current = pub
            setPublisher(pub)
            setMainStreamManager(pub)

        });

    }

    const getRoomInfo = () => {

        axios.post(API + "/api/roomInfo", {
            method: 'POST',
            body: JSON.stringify({ sessionID: id })
        }).then((response) => {
            // console.log('ROOOM INFO', response)
            countPeople(response.data.connections.content)
        }).catch((error) => {
            // LOS 403 Y 404 SE CONTROLAN AQUI
            console.log(error.response)
        })
    }

    const raiseHand = (person) => {
        session.signal({ data: person, to: [], type: "raiseHand" })
    }

    const sendVideo = (vid) => {
        session.signal({ data: JSON.stringify(vid), to: [], type: "sendVideo" })
    }

    const acceptUser = async (sub) => {
        const variables = { password: parseInt(password), sessionID: id, user: sub }
        axios.post(API + "/api/approveUser", {
            method: 'POST',
            body: JSON.stringify(variables)
        }).then((response) => {
            // console.log('QUE PASO?', response)
        }).catch((error) => {
            // console.log(error)
            alert("Ha ocurrido un error al permitir entrar a este usuario")
        })
    }

    const banUser = (sub) => {
        const variables = { password: parseInt(password), sessionID: id, user: sub }
        axios.post(API + "/api/banUser", {
            method: 'POST',
            body: JSON.stringify(variables)
        }).then((response) => {
            console.log('QUE PASO?', response)
        }).catch((error) => {
            console.log(error)
            alert("Ha ocurrido un error al bloquear a este usuario")
        })
    }

    const removeUser = (sub) => {
        const variables = { password: parseInt(password), sessionID: id, user: sub }
        axios.post(API + "/api/removeUser", {
            method: 'POST',
            body: JSON.stringify(variables)
        }).then((response) => {
            console.log('QUE PASO?', response)
        }).catch((error) => {
            console.log(error)
            alert("Ha ocurrido un error al expulsar a este usuario")
        })
    }

    const replaceCam = () => {
        setScreenShare(false)
        session.signal({ data: "false", to: [], type: "screenSharing" })
        const mm = {
            // videoSource: "camera",
            publishAudio: microphone,
            publishVideo: video,
            resolution: `450x300`,
            frameRate: 24,
            mirror: false,
        }

        OV.getUserMedia(mm).then((media) => {
            var track = media.getVideoTracks()[0];
            publisher.replaceTrack(track);
        });
    }

    const activateScreen = () => {

        var videoSource = navigator.userAgent.indexOf('Firefox') !== -1 ? 'window' : 'screen';
        const mm = {
            videoSource,
            publishAudio: microphone,
            publishVideo: video,
        }
        OV.getUserMedia(mm).then((media) => {
            var track = media.getVideoTracks()[0];
            publisher.replaceTrack(track);
            track.addEventListener('inactive', () => {
                // activateCam(true)
                replaceCam()
                session.signal({ data: "false", to: [], type: "screenSharing" })
            })
            track.addEventListener('ended', () => {
                // activateCam(true)
                replaceCam()
                session.signal({ data: "false", to: [], type: "screenSharing" })
            })
            setScreenShare(!screenShare)
            session.signal({ data: "true", to: [], type: "screenSharing" })
        });
    }

    const toogleScreenShare = () => {
        if (screenShare) {
            replaceCam()
            setPersonalScreenShare(false)
        } else {
            activateScreen()
            setPersonalScreenShare(true)
        }
    }

    const getSize = () => {
        const amount = subscribers.length + 1
        if (visualization === "GRID") {
            if (size === "small") {
                if (amount <= 2) {
                    return { width: "100%", height: null }
                } else if (amount === 3) {
                    return { width: "50%", height: null }
                } else if (amount <= 4) {
                    return { width: "50%", height: null }
                } else if (amount <= 6) {
                    return { width: "50%", height: "33.3%" }
                } else if (amount <= 9) {
                    return { width: "33.3%", height: "33.3%" }
                } else {
                    return { width: `33.3%`, height: "25%" }
                }
            } else {
                if (amount <= 2) {
                    return { width: "50%", height: null }
                } else if (amount <= 4) {
                    return { width: "50%", height: null }
                } else if (amount <= 6) {
                    return { width: "33.3%", height: null }
                } else if (amount <= 16) {
                    return { width: "25%", height: null }
                } else if (amount <= 20) {
                    return { width: "25%", height: null }
                } else {
                    return { width: "20%", height: "20%" }
                }
            }

        } else {
            if (size === "small") {
                return { width: "50%", height: "100%" }
            } else {
                return { width: `25vh`, height: "100%" }
            }
        }
    }

    const videosPerPage = visualization === "GRID" ? size === "small" ? 12 : 25 : size === "small" ? 3 : 5

    return (
        // <Beforeunload onBeforeunload={() => "You'll lose your data!"} >
        <Box flex="grow">

            {/* BLOQUE PRINCIPAL DE VIDEO */}
            <Box flex="grow" direction="row">
                {session.openvidu.role === "MODERATOR" &&
                    <Box
                        className="fluidVideo"
                        style={adminMenu ?
                            {
                                zIndex: 20,
                                width: 400,
                                transform: "translate(0px, 0px)",
                                position: "absolute",
                                height: "100%",
                                backgroundColor: "white"
                            }
                            :
                            {
                                zIndex: 20,
                                width: 400,
                                transform: "translate(-400px, 0px)",
                                position: "absolute",
                                height: "100%",
                                backgroundColor: "white"
                            }
                        }
                    >
                        <Box flex="grow">
                            <Box background={{ color: "white", opacity: "medium" }} overflow="auto" flex="grow">


                                <Box direction="row" pad={{ left: "small" }}>
                                    <Box flex justify="center">
                                        <Heading level="4" margin="none">Información de la sala</Heading>
                                    </Box>
                                    <Button onClick={() => setAdminMenu(!adminMenu)} icon={<Icon.Close />} />
                                </Box>

                                <Box height="auto" background="light-1">
                                    <Box gap="small" flex="grow" pad="small">
                                        <Box gap="small" align="start">
                                            <Text size="small">Tiempo en la sala</Text>
                                            <SessionTimer />
                                        </Box>
                                        <Text size="small">Compartir Sala</Text>
                                        <Box direction="row" gap="small" >
                                            <Button
                                                size="small"
                                                style={{ borderRadius: 7, color: "white", paddingLeft: 10, paddingRight: 10 }}
                                                primary
                                                color={linkCoppied ? "status-ok" : "brand"}
                                                label={linkCoppied ? "Copiado" : "Enlace"}
                                                onClick={() => {
                                                    setLinkCoppied(true)
                                                    navigator.clipboard.writeText(`https://rffm.novanetlive.com/sala/${id}?s=${clientData.password}`)
                                                    setTimeout(() => {
                                                        setLinkCoppied(false)
                                                    }, 2000)
                                                }}
                                                icon={linkCoppied ? <Icon.StatusGood color="white" /> : <Icon.Link />}
                                            />
                                            <Button size="small" style={{ borderRadius: 7, paddingLeft: 10, paddingRight: 10 }} primary label="Email" href={`mailto:?&subject=Invitación a ${data.title}&body=Buenos días,%0D%0A${clientData.userName} de ${clientData.userTeam} te invita a acceder a ${data.title}.%0D%0A %0D%0AEl enlace para acceder a la sala es: https://rffm.novanetlive.com/sala/${id}?s=${clientData.password}.%0D%0APodrás acceder con la contraseña: ${clientData.password}`} icon={<Icon.Mail />} />
                                            <Button size="small" style={{ borderRadius: 7, paddingLeft: 10, paddingRight: 10 }} primary label="Whatsapp" href={`whatsapp://send?text=Invitación para acceder a la sala: https://rffm.novanetlive.com/sala/${id}?s=${clientData.password}`} data-action="share/whatsapp/share" icon={<Icon.ShareOption />} />

                                        </Box>
                                    </Box>
                                    <Box pad="small">
                                        <Text>Contraseña de la sala: <Text weight="bold">{clientData.password}</Text></Text>
                                    </Box>
                                </Box>
                                <Box flex="grow">
                                    <Accordion>
                                        <AccordionPanel label={
                                            <Box align="center" responsive="false" direction="row" gap="small" pad={{ left: "small", vertical: "small" }} >
                                                {subscribers.length > 0 && <Box height="30px" width="30px" round="100%" background="dark-4" justify="center" align="center">
                                                    <Text weight="bold" color="white" size="10px">{subscribers.length}</Text>
                                                </Box>}
                                                <Text weight="bold">Participantes Emitiendo</Text>
                                            </Box>
                                        }>

                                            <Box background="light-1" overflow="auto" height={{ min: "50px", max: "30vh" }} pad={{ left: "small" }}>
                                                {subscribers.length === 0 ?
                                                    <Box flex align="center" justify="center">
                                                        <Text size="xsmall" textAlign="center">Sin Participantes</Text>
                                                    </Box>
                                                    :
                                                    subscribers.map((sub, i) => {
                                                        const userData = sub.stream && sub.stream.connection.data && JSON.parse(sub.stream.connection.data).clientData
                                                        return (
                                                            <Box key={i} direction="row" height={{ min: "50px" }} justify="between" align="center">
                                                                <Box flex>
                                                                    <Text size="xsmall">{i + 1} - {sub.stream && userData.userName} | {sub.stream && userData.userTeam}</Text>
                                                                </Box>
                                                                <Box direction="row">
                                                                    {sub.stream && hands.includes(sub.stream.connection.connectionId) &&
                                                                        <Button style={{ fontSize: 25, padding: 10 }} label="✋" onClick={() => raiseHand(sub.stream.connection.connectionId)} />
                                                                    }
                                                                    <Button hoverIndicator onClick={() => muteVideoSubscriber(sub)} icon={<Icon.Video color={sub.stream && sub.stream.videoActive ? "gray" : "status-critical"} />} />
                                                                    <Button hoverIndicator onClick={() => muteAudioSubscriber(sub)} icon={<Icon.Microphone color={sub.stream && sub.stream.audioActive ? "gray" : "status-critical"} />} />
                                                                    <Button hoverIndicator onClick={() => kickOutUser(sub)} icon={<Icon.Logout color="status-critical" />} />
                                                                </Box>
                                                            </Box>
                                                        )
                                                    })
                                                }
                                            </Box>
                                            <Box pad="small" direction='row' gap="small">
                                                <Button onClick={() => subscribers.map(s => muteAudioSubscriber(s, false))} icon={<Icon.Microphone size="19px" color={"white"} />} color="dark-2" size="small" style={{ borderRadius: 7, padding: 10, paddingTop: 5, paddingBottom: 5, fontSize: 12 }} primary label="Cerrar Microfonos" />
                                                <Button onClick={() => subscribers.map(s => muteVideoSubscriber(s, false))} icon={<Icon.Video size="19px" color={"white"} />} color="dark-2" size="small" style={{ borderRadius: 7, padding: 10, paddingTop: 5, paddingBottom: 5, fontSize: 12 }} primary label="Cerrar Camaras" />
                                            </Box>
                                        </AccordionPanel>
                                        <AccordionPanel label={
                                            <Box align="center" direction="row" responsive="false" gap="small" pad={{ left: "small", vertical: "small" }} >
                                                {data.waitingRoom.length > 0 && <Box height="30px" width="30px" round="100%" background="status-critical" justify="center" align="center">
                                                    <Text weight="bold" color="white" size="10px">{data.waitingRoom.length}</Text>
                                                </Box>}
                                                <Text weight="bold">Sala de Espera</Text>
                                            </Box>
                                        }
                                        >
                                            <Box background="light-1" overflow="auto" height={{ min: "50px", max: "30vh" }} pad={{ left: "small" }}>
                                                {data.waitingRoom.length === 0 ?
                                                    <Box flex align="center" justify="center">
                                                        <Text size="xsmall" textAlign="center">Sin Participantes</Text>
                                                    </Box>
                                                    :
                                                    data.waitingRoom.map((sub, i) => {
                                                        return (
                                                            <Box key={i} direction="row" justify="between" align="center">
                                                                <Box flex>
                                                                    <Text size="xsmall">{i + 1} - {atob(sub).split(",")[0]} | {atob(sub).split(",")[1]}</Text>
                                                                </Box>
                                                                <Box direction="row">
                                                                    <Button hoverIndicator onClick={() => acceptUser(sub)} icon={<Icon.StatusGood color={"status-ok"} />} />
                                                                    <Button hoverIndicator onClick={() => banUser(sub)} icon={<Icon.Alert color="status-critical" />} />
                                                                </Box>
                                                            </Box>
                                                        )
                                                    })
                                                }
                                            </Box>
                                            {data.waitingRoom.length > 1 && <Box pad="small" direction='row' gap="small">
                                                <Button onClick={() => data.waitingRoom.map(r => acceptUser(r))} color="dark-2" size="small" style={{ borderRadius: 7 }} primary label="Aprobar Todos" />
                                            </Box>}
                                        </AccordionPanel>
                                        <AccordionPanel label={
                                            <Box align="center" direction="row" responsive="false" gap="small" pad={{ left: "small", vertical: "small" }} >
                                                {data.banned.length > 0 && <Box height="30px" width="30px" round="100%" background="status-critical" justify="center" align="center">
                                                    <Text weight="bold" color="white" size="10px">{data.banned.length}</Text>
                                                </Box>}
                                                <Text weight="bold">Bloqueados</Text>
                                            </Box>
                                        }>
                                            <Box background="light-1" overflow="auto" height={{ min: "50px", max: "30vh" }} pad={{ left: "small" }}>
                                                {data.banned.length === 0 ?
                                                    <Box flex align="center" justify="center">
                                                        <Text size="xsmall" textAlign="center">Sin Participantes</Text>
                                                    </Box>
                                                    :
                                                    data.banned.map((sub, i) => {
                                                        return (
                                                            <Box key={i} direction="row" justify="between" align="center">
                                                                <Box flex>
                                                                    <Text>{i + 1} - {atob(sub).split(",")[0]} | {atob(sub).split(",")[1]}</Text>
                                                                </Box>
                                                                <Box direction="row">
                                                                    <Button hoverIndicator onClick={() => acceptUser(sub)} icon={<Icon.StatusGood color={"status-ok"} />} />
                                                                    {/* <Button onClick={() => banUser(sub)} icon={<Icon.Alert color="status-critical" />} /> */}
                                                                </Box>
                                                            </Box>
                                                        )
                                                    })
                                                }
                                            </Box>
                                        </AccordionPanel>
                                        <AccordionPanel label={
                                            <Box align="center" responsive="false" direction="row" gap="small" pad={{ left: "small", vertical: "small" }} >
                                                {people.length > 0 && <Box height="30px" width="30px" round="100%" background="dark-4" justify="center" align="center">
                                                    <Text weight="bold" color="white" size="10px">{people.length}</Text>
                                                </Box>}
                                                <Text weight="bold">Usuarios Conectados</Text>
                                            </Box>
                                        }>

                                            <Box background="light-1" overflow="auto" height={{ min: "50px", max: "30vh" }} pad={{ left: "small" }}>
                                                {people.length === 0 ?
                                                    <Box flex align="center" justify="center">
                                                        <Text size="xsmall" textAlign="center">Sin Participantes</Text>
                                                    </Box>
                                                    :
                                                    people.map((sub, i) => {
                                                        const userData = JSON.parse(sub.clientData).clientData
                                                        return (
                                                            <Box key={i} direction="row" height={{ min: "50px" }} justify="between" align="center">
                                                                <Box flex>
                                                                    <Text size="xsmall">{i + 1} - {userData.userName} | {userData.userTeam}</Text>
                                                                </Box>
                                                                <Box direction="row">
                                                                    {/* <Button hoverIndicator onClick={() => muteVideoSubscriber(sub)} icon={<Icon.Video color={sub.stream && sub.stream.videoActive ? "gray" : "status-critical"} />} />
                                                                     <Button hoverIndicator onClick={() => muteAudioSubscriber(sub)} icon={<Icon.Microphone color={sub.stream && sub.stream.audioActive ? "gray" : "status-critical"} />} /> */}
                                                                    {sub.connectionId !== session.connection.connectionId && <Button hoverIndicator onClick={() => kickViewer(sub)} icon={<Icon.Logout color="status-critical" />} />}
                                                                </Box>
                                                            </Box>
                                                        )
                                                    })
                                                }
                                            </Box>
                                        </AccordionPanel>
                                    </Accordion>
                                </Box>
                                {/* CONECTADOS */}
                            </Box>
                        </Box>
                    </Box>
                }


                <Box flex="grow" background="dark-1" style={{ position: "relative" }}>

                    {/* LISTADO DE PERSONAS EN CUADROS DE VIDEO */}
                    {subscribers.length > 0 &&
                        <Box
                            flex={visualization === "GRID" ? "grow" : false}
                            pad="small"
                            direction="row"
                            justify="between"
                            style={{ position: "relative", overflow: "hidden" }}
                        >

                            <Box justify="center" style={{ position: "absolute", left: 10, top: "50%", zIndex: 10, transform: "translate(0, -50%)" }}>
                                {page > 1 &&
                                    <Button fill color="white" style={{ borderRadius: "100%", padding: "0px 3px", height: 30, width: 30 }} onClick={() => setPage(page - 1)} icon={<Icon.FormPrevious />} round="100%" primary />
                                }
                            </Box>


                            <Box
                                style={{
                                    display: "flex",
                                    flexWrap: visualization === "GRID" ? "wrap" : "nowrap",
                                    height: visualization === "GRID" ? null : "20vh",
                                    // flex: visualization === "GRID" ? 1 : null
                                }}
                                className="fluidVideo"
                                flex={"grow"}
                                direction="row"
                                wrap={visualization === "GRID" ? true : false}
                                justify={visualization === "GRID" ? "center" : size !== "small" ? "center" : "start"}
                                overflow={{ horizontal: size === "small" && visualization !== "GRID" ? "auto" : "auto" }}
                            >
                                {/* ESTE PRIMER CUADRADO  ES EL USUARIO */}
                                {session.openvidu.role !== "SUBSCRIBER" && publisher &&
                                    <Box
                                        focusIndicator={false}
                                        onClick={() => changeMainVideo(publisher, undefined)}
                                        width={getSize().width}
                                        height={getSize().height}
                                        className="fluidVideo"
                                        round="small"
                                        background="black"
                                        round="10px"
                                        border={{ side: "all", size: streamSelected !== undefined ? "5px" : "4px", color: streamSelected !== undefined ? "dark-1" : "status-ok" }}
                                        style={{ position: "relative", width: page === 1 ? null : 0, border: page === 1 ? null : 0, visibility: page === 1 ? "visible" : "hidden" }}
                                    >
                                        {hands.includes(session.connection.connectionId) && <Text className="hand" style={{ position: "absolute", zIndex: 10, top: 5, left: 5 }} size="large">✋</Text>}
                                        <VideoComponent style={{ zIndex: 2, position: "absolute", width: "100%", height: "100% ", objectFit: "cover", borderRadius: 7 }} stream={publisher} />
                                        {!publisher.stream.videoActive &&
                                            <Box align="center" justify="center" style={{ pointerEvents: "none", zIndex: 2, position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
                                                <Box background="dark-1" pad={{ horizontal: "10px", vertical: "5px" }} round="30px">
                                                    <Heading level="3" margin="none">
                                                        {publisher.stream.connection && publisher.stream.connection.data && JSON.parse(publisher.stream.connection.data).clientData && JSON.parse(publisher.stream.connection.data).clientData.userName[0]}
                                                    </Heading>
                                                </Box>
                                            </Box>
                                        }

                                        < Box direction="row" pad={{ vertical: "3px", horizontal: "5px" }} style={{ zIndex: 3, position: "absolute", bottom: 0, left: 0, borderTopRightRadius: 5 }} background="rgba(0,0,0,.5)" align="center" gap="3px">
                                            {!publisher.stream.audioActive && <Icon.Microphone color="status-critical" size="15px" />}
                                            <Text size="small" truncate color="white" >
                                                {publisher.stream.connection && publisher.stream.connection.data && JSON.parse(publisher.stream.connection.data).clientData && JSON.parse(publisher.stream.connection.data).clientData.userName} | {publisher.stream.connection && publisher.stream.connection.data && JSON.parse(publisher.stream.connection.data).clientData && JSON.parse(publisher.stream.connection.data).clientData.userTeam}
                                            </Text>
                                        </Box>
                                    </Box>

                                }
                                {/* AQUI ESTAN EL RESTO DE USUARIOS */}
                                {subscribers.map((sub, i) => {
                                    const visible = (i + 1) / videosPerPage < page && (i + 1) / videosPerPage >= page - 1

                                    return (
                                        <Box
                                            key={i}
                                            focusIndicator={false}
                                            onClick={() => changeMainVideo(sub, i)}
                                            width={getSize().width}
                                            height={getSize().height}
                                            round="small"
                                            className="fluidVideo"
                                            background="black"
                                            round="10px"
                                            border={{ side: "all", size: streamSelected !== i ? "5px" : "4px", color: streamSelected !== i ? "dark-1" : "status-ok" }}
                                            style={{ position: "relative", width: visible ? null : 0, border: visible ? null : 0, visibility: visible ? "visible" : "hidden" }}
                                        >
                                            {sub.stream && hands.includes(sub.stream.connection.connectionId) && <Text className="hand" style={{ position: "absolute", zIndex: 10, top: 5, left: 5 }} size="large">✋</Text>}
                                            {sub.stream && sub.stream.hasVideo &&
                                                <VideoComponent style={{ position: "absolute", width: "100%", zIndex: 2, height: "100% ", objectFit: "cover", borderRadius: 7 }} stream={sub} />
                                            }
                                            {sub.stream && !sub.stream.videoActive &&
                                                <Box align="center" justify="center" style={{ pointerEvents: "none", zIndex: 2, position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
                                                    <Box background="dark-1" pad={{ horizontal: "10px", vertical: "5px" }} round="30px">
                                                        <Heading level="3" margin="none">
                                                            {sub.stream.connection.data && JSON.parse(sub.stream.connection.data).clientData && JSON.parse(sub.stream.connection.data).clientData.userName[0]}
                                                        </Heading>
                                                    </Box>
                                                </Box>
                                            }
                                            < Box direction="row" pad={{ vertical: "3px", horizontal: "5px" }} style={{ zIndex: 3, position: "absolute", bottom: 0, left: 0, borderTopRightRadius: 5 }} background="rgba(0,0,0,.5)" align="center" gap="3px">
                                                {sub.stream && !sub.stream.audioActive && <Icon.Microphone color="status-critical" size="15px" />}
                                                <Text size="small" truncate color="white" >
                                                    {sub.stream && sub.stream.connection.data && JSON.parse(sub.stream.connection.data).clientData && JSON.parse(sub.stream.connection.data).clientData.userName} | {sub.stream && sub.stream.connection.data && JSON.parse(sub.stream.connection.data).clientData && JSON.parse(sub.stream.connection.data).clientData.userTeam}
                                                </Text>
                                            </Box>
                                        </Box>
                                    )
                                })}
                            </Box>

                            <Box justify="center" style={{ position: "absolute", right: 10, top: "50%", zIndex: 10, transform: "translate(0, -50%)" }}>
                                {subscribers.length + 1 > videosPerPage && subscribers.length + 1 > page * videosPerPage &&
                                    <Button fill color="white" style={{ borderRadius: "100%", padding: "0px 3px", height: 30, width: 30 }} onClick={() => setPage(page + 1)} icon={<Icon.FormNext />} round="100%" primary />
                                }
                            </Box>
                        </Box>
                    }


                    {/* VIDEO DESTACADO O REPRODUCTOR PRINCIPAL */}
                    {visualization === "FEATURED" &&
                        <Box ref={maximizableElement}
                            style={{ position: "relative" }}
                            align="center"
                            with="100%"
                            pad={{ horizontal: "small", bottom: "small" }}
                            flex="grow"
                        >
                            {mainStreamManager &&
                                <Box
                                    className="fluidVideo"
                                    background="black"
                                    round="10px"
                                    height={{ min: fullScreenElement === 0 ? size === "small" ? "100%" : "100%" : visualization === "GRID" ? "0px" : subscribers.length !== 0 ? "calc( 100vh - 180px - 140px )" : size === "small" ? "100%" : "100%" }}
                                    width={{ max: isFullscreen ? "100%" : size === "small" ? "100%" : size === "medium" ? "1024px" : subscribers.length !== 0 ? "calc( 100vh * 1.33 )" : "100%" }}
                                    style={{ width: "100%", position: "relative" }}
                                    flex="grow"
                                >
                                    {/* {loadingCam &&
                                        <Box style={{position:"absolute", zIndex:10, top:0, bottom:0, left:0, right:0}} align="center" justify="center" flex="grow">
                                            <Loader
                                                type="Watch"
                                                color="white"
                                                height={120}
                                                width={120}
                                            />
                                        </Box>
                                    } */}
                                    {hands.includes(session.connection.connectionId) && <Text className="hand" style={{ position: "absolute", zIndex: 10, top: 5, left: 5 }} size="large">✋</Text>}
                                    {videoPlayer.status ?
                                        <Box flex="grow" style={{ position: "relative", zIndex: 0 }}>
                                            {session.openvidu.role === "MODERATOR" &&
                                                <Box background="dark-1" pad="small" round="50px" style={{ zIndex: 3, position: "absolute", top: 10, right: "50%", transform: "translate(50%, 0)" }}>
                                                    <Box direction="row" gap="small">
                                                        <Button onClick={() => sendVideo({ link: "", status: false, time: 0, playing: false, seek: 0, duration: 0, position: 0 })} icon={<Icon.Close />} />
                                                        <Button onClick={() => sendVideo({ seek: -10 })} icon={<Icon.BackTen />} />
                                                        <Button onClick={() => sendVideo({ playing: !videoPlayer.playing })} icon={videoPlayer.playing ? <Icon.Pause /> : <Icon.Play />} />
                                                        <Button onClick={() => sendVideo({ seek: 10 })} icon={<Icon.ForwardTen />} />
                                                        <Button
                                                            onClick={() => {
                                                                sendVideo({
                                                                    status: true,
                                                                    playing: true,
                                                                    link: videoPlayerStatus.current.link,
                                                                    position: videoPlayerRef.current.getCurrentTime()
                                                                })
                                                            }}
                                                            icon={<Icon.Sync />}
                                                        />
                                                    </Box>
                                                </Box>
                                            }
                                            <ReactPlayer
                                                config={{
                                                    youtube: {
                                                        playerVars: { showinfo: 1, start: videoPlayer.time, rel: 0 }
                                                    }
                                                }}
                                                ref={videoPlayerRef}
                                                style={{ position: "relative", zIndex: 0, flex: 1 }}
                                                width="100%"
                                                url={videoPlayer.link}
                                                playing={videoPlayer.playing}
                                            />
                                        </Box>
                                        :
                                        <VideoComponent style={{ position: "absolute", zIndex: 2, width: "100%", borderRadius: 10, height: "100% ", objectFit: screenShare ? "contain" : "cover" }} stream={mainStreamManager} />
                                    }
                                    {!mainStreamManager.stream.videoActive && !videoPlayer.status &&
                                        <Box align="center" justify="center" style={{ pointerEvents: "none", zIndex: 2, position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
                                            <Box background="dark-1" pad={{ horizontal: "10px", vertical: "5px" }} round="30px">
                                                <Heading level="3" margin="none">
                                                    {mainStreamManager.stream.connection && mainStreamManager.stream.connection.data && JSON.parse(mainStreamManager.stream.connection.data).clientData && JSON.parse(mainStreamManager.stream.connection.data).clientData.userName[0]}
                                                </Heading>
                                            </Box>
                                        </Box>
                                    }
                                </Box>
                            }
                            {isFullscreen &&
                                <Button color="brand"
                                    primary
                                    color="white"
                                    style={{ zIndex: 10, borderRadius: "100%", position: "absolute", bottom: 10, left: 10 }}
                                    icon={<Icon.Contract />}
                                    onClick={() => {
                                        setFullScreenElement(null)
                                        document.exitFullscreen()
                                    }} />
                            }
                        </Box>
                    }

                </Box >

            </Box>


            {/* BARRA INFERIOR DE FUNCIONES */}
            {screenShare && !personalScreenShare && mainStreamManager.stream.connection.connectionId !== session.connection.connectionId && <ReactTooltip place="top" type="warning" effect="solid" />}
            <Box
                style={{
                    zIndex: 10,
                    position: size === "small" ? "relative" : "absolute",
                    bottom: size === "small" ? 0 : 20,
                    left: size === "small" ? null : "50%",
                    transform: size === "small" ? null : "translate(-50%, 0)",
                    overflow: "auto"
                }}
                round={size === "small" ? '0px' : "50px"}
                background="#191919"
                // direction="row"
                // gap="small"
                pad="10px"
            >
                <Box flex="grow" direction="row" gap="small">
                    {session.openvidu.role !== "SUBSCRIBER" &&
                        <Button
                            color={!microphone ? "rgba(256,256,256,.3)" : "white"}
                            primary
                            style={{ borderRadius: "100%" }}
                            icon={<Icon.Microphone color={!microphone ? "white" : "black"} />}
                            onClick={() => {
                                switchMic()
                                userMicrophone.current = !microphone
                            }}
                        />
                    }

                    {session.openvidu.role !== "SUBSCRIBER" &&
                        <Button
                            style={{ borderRadius: "100%" }}
                            color={!video ? "rgba(256,256,256,.3)" : "white"}
                            primary
                            icon={<Icon.Video color={!video ? "white" : "black"} />}
                            onClick={() => {
                                switchVideo()
                                userVideo.current = !video
                            }}
                        />
                    }

                    {!isMobile && !isSafari && session.openvidu.role !== "SUBSCRIBER" &&
                        <Button data-tip="Otro usuario está compartiendo pantalla" ref={shareButtonRef} disabled={screenShare && !personalScreenShare} style={{ borderRadius: "100%" }} color={!screenShare ? "rgba(256,256,256,.3)" : "white"} primary icon={<Icon.Monitor color={!screenShare ? "white" : "black"} />}
                            onClick={toogleScreenShare}
                        />
                    }


                    <Box style={{ position: "relative", flex: "grow", minWidth: 48 }}>
                        <Button style={{ borderRadius: "100%" }} color={!chatOpen.current ? "rgba(256,256,256,.3)" : "white"} primary icon={<Icon.Chat color={!chatOpen.current ? "white" : "black"} />} onClick={() => { toogleChat() }} />
                        {messagesCounter > 0 &&
                            <Box style={{ position: "absolute", top: -5, right: -5 }} width="15px" height="15px" background="status-critical" align="center" justify="center" round >
                                <Text style={{ fontSize: 10, lineHeight: "7px" }}>{messagesCounter}</Text>
                            </Box>
                        }
                    </Box>

                    {visualization === "FEATURED" && !iOSSafari && !isSafari &&
                        <Button
                            style={{ borderRadius: "100%" }}
                            color={fullScreenElement !== 0 ? "rgba(256,256,256,.3)" : "white"}
                            primary
                            icon={<Icon.Expand color={fullScreenElement !== 0 ? "white" : "black"} />}
                            onClick={() => { setIsFullscreen() }} />
                    }

                    {/* BOTONES TIPOS DE VISUALIZACION */}
                    {subscribers.length > 0 &&
                        <Button style={{ borderRadius: "100%" }} primary color="rgba(256,256,256,.3)" onClick={changeVisualization} icon={visualization === "GRID" ? <Icon.Channel color="white" /> : <Icon.Grid color="white" />} />
                    }



                    <Button
                        style={{ borderRadius: "100%" }}
                        color={!handRaised ? "rgba(256,256,256,.3)" : "white"}
                        primary
                        icon={<Icon.Halt color={!handRaised ? "white" : "black"} />}
                        onClick={() => {
                            raiseHand(session.openvidu.session.connection.connectionId)
                            setHandRaised(!handRaised)
                        }}
                    />


                    {session.openvidu.role === "MODERATOR" &&
                        <Button
                            style={{ borderRadius: "100%" }}
                            color={!videoPlayer.status ? "rgba(256,256,256,.3)" : "white"}
                            primary
                            icon={<Icon.CirclePlay color={!videoPlayer.status ? "white" : "black"} />}
                            onClick={() => {
                                videoPlayer.status ? sendVideo({ link: "", time: 0, playing: false, show: false, status: false }) : setContextVideo(true)
                            }}
                        />
                    }
                    {/* BOTON DE ADMINISTRADOR */}
                    {session.openvidu.role === "MODERATOR" &&
                        <Box style={{ position: "relative", flex: "grow", minWidth: 48 }}>
                            <Button
                                style={{ borderRadius: "100%" }}
                                color={!adminMenu ? "rgba(256,256,256,.3)" : "white"}
                                primary
                                icon={<Icon.SettingsOption color={!adminMenu ? "white" : "black"} />}
                                onClick={() => setAdminMenu(!adminMenu)}
                            />
                            {data.waitingRoom && data.waitingRoom.length > 0 &&
                                <Box style={{ position: "absolute", top: -5, right: -5, width: 15, height: 15, borderRadius: 10 }} background="status-critical" align="center" justify="center" >
                                    <Text style={{ fontSize: 10, lineHeight: "7px" }}>{data.waitingRoom.length}</Text>
                                </Box>
                            }
                        </Box>
                    }

                    <Button
                        style={{ borderRadius: "100%" }}
                        primary
                        color="status-critical"
                        onClick={() => setCloseSession(true)}
                        icon={<Icon.Power color="white" />}
                    />
                </Box>

            </Box>

            {chatOpen.current &&
                <Layer
                    onClickOutside={() => toogleChat()}
                    onEsc={() => toogleChat()}
                    // margin={{ right: "20px", top: "100px" }}
                    position="top-right"
                    modal={false}
                    open={chatOpen.current}
                    responsive={false}
                    plain
                >
                    <Box background="rgba(0,0,0,.8)" round="7px" width="medium" height={{ max: "large" }} flex="grow" elevation="large">
                        <Box height="50px" direction="row" pad={{ left: "medium" }}>
                            <Box flex justify="center">
                                <Heading color="white" level="4" margin="none">Chat</Heading>
                            </Box>
                            <Button onClick={() => toogleChat()} hoverIndicator icon={<Icon.Close color="white" />} />
                        </Box>

                        <Box ref={messagesContainer} height={{ min: "small", max: "medium" }} overflow="auto" flex="grow" pad="medium" gap="small">
                            {messages.map((mes, i) => {
                                return (
                                    <Box key={i} style={{ position: "relative" }} gap="small" height={{ min: "50px" }} margin={{ top: "15px" }} width="100%" align="center" flex="grow" direction={mes.connectionId === publisher.session.connection.connectionId ? "row-reverse" : "row"}>
                                        {/* <canvas id={'userImg-' + i} style={{ width: 60, height: 60, borderRadius: 30, objectFit:"cover" }} /> */}
                                        <Box>
                                            <Text textAlign={mes.connectionId === publisher.session.connection.connectionId ? "end" : "start"} weight="bold" size="small" color="white">{mes.nickname} | {mes.team}</Text>
                                            <Paragraph style={{ wordBreak: "break-all" }} textAlign={mes.connectionId === publisher.session.connection.connectionId ? "end" : "start"} color="white">{mes.message}</Paragraph>
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Box>
                        <Keyboard onEnter={() => message !== "" && sendMessage()} >
                            <Box flex="grow" direction="row" align="center" pad="small">
                                <TextInput value={message} onChange={(ev) => setMessage(ev.target.value)} />
                                <Button disabled={message === ""} hoverIndicator onClick={() => sendMessage()} icon={<Icon.Send />} />
                            </Box>
                        </Keyboard>
                    </Box>
                </Layer>
            }
            {/* session.openvidu.role === "SUBSCRIBER" && setMainStreamManager(subscribers[0]) */}


            {/* ADMIN CERRAR SESION */}
            {closeSession &&
                <Layer
                    onClickOutside={() => !closing && setCloseSession(false)}
                    onEsc={() => !closing && setCloseSession(false)}
                    open={closeSession}
                    responsive={false}
                >
                    <Box pad="medium" width="medium" round="small" gap="medium">
                        <Heading level="3" textAlign="center" margin="none">Vas a salir de la sesión</Heading>
                        <Box gap="small">
                            {session.openvidu.role === "MODERATOR" && <Button onClick={async () => {
                                closeRoom()
                            }} primary color="status-critical" style={{ color: "white" }} disabled={closing} label={closing ? "Cerrando..." : "Cerrar Sala"} />}
                            <Button disabled={closing} onClick={() => {
                                leaveSession()
                            }} primary color="dark-2" label="Salir" />
                            <Button disabled={closing} onClick={() => setCloseSession(false)} primary color="light-4" label="Cancelar" />
                        </Box>
                    </Box>
                </Layer>
            }

            {/* WATCH VIDEO */}
            {contextVideo &&
                <Layer
                    // position={"left"}
                    // modal={false}
                    responsive={false}
                    open={videoPlayer.show}
                    onClickOutside={() => setContextVideo(false)}
                    onEsc={() => setContextVideo(false)}
                    open={closeSession}
                >
                    <Box direction="row">
                        <Box flex justify="center" pad={{ left: "10px" }}>
                            <Heading level="4" margin="none">Compartir video</Heading>
                        </Box>
                        <Button onClick={() => setContextVideo(false)} icon={<Icon.Close />} />
                    </Box>
                    <Box pad="medium" gap="medium">
                        <TextInput value={videoPlayer.link} onChange={(ev) => setVideoPlayer({ ...videoPlayer, link: ev.target.value })} placeholder="Link del video..." />
                        {videoPlayer.link && videoPlayer.link.length > 0 && videoPlayer.link.includes("yout") && <TextInput value={videoPlayer.time} type="number" onChange={(ev) => setVideoPlayer({ ...videoPlayer, time: ev.target.value })} placeholder="Segundo Inicial" />}
                        <CheckBox value={videoPlayer.playing} label="Reproducir al enviar" onChange={(ev) => setVideoPlayer({ ...videoPlayer, playing: ev.target.checked })} placeholder="Segundo Inicial" />
                        <Box round="small" width="medium" background="light-2" round="5px">
                            <ReactPlayer
                                url={videoPlayer.link}
                                width="100%"
                                onDuration={ev => { setVideoPlayer({ ...videoPlayer, duration: ev }) }}
                                config={{
                                    youtube: {
                                        playerVars: { showinfo: 1, start: videoPlayer.time, rel: 0 }
                                    }
                                }}
                                playing={false}
                            />
                        </Box>
                        <Box align="end">
                            <Button disabled={videoPlayer.link === ""} label="Compartir" primary onClick={() => {
                                sendVideo({ link: videoPlayer.link, status: true, time: videoPlayer.time, playing: videoPlayer.playing })
                                setContextVideo(false)
                            }} icon={<Icon.Send />} />
                        </Box>
                    </Box>
                </Layer>
            }


            {!compatible &&
                <Layer
                    open={!compatible}
                    responsive={false}
                >
                    <Box pad="medium" width="medium" round="small" gap="medium">
                        <Heading level="3" textAlign="center" margin="none">Navegador No compatible</Heading>
                    </Box>
                </Layer>
            }
        </Box >
        // </Beforeunload>
    );
}

export default VideoRoom;