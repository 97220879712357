import React, { useEffect, useRef } from 'react';

const VideoComponent = ({ stream, style = {} }) => {

    const videoRef = useRef()


    useEffect(() => {
        stream.addVideoElement(videoRef.current)
    }, [stream])

    return (
        <video style={{...style}} autoPlay={true} playsInline={true} ref={videoRef} />
    );
}

export default VideoComponent;