import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Heading, Text, TextInput, Button, Keyboard, Layer } from 'grommet';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { firestore } from 'firebase/app'
import moment from 'moment'
import UserContext from '../context/UserContext';
import Loader from 'react-loader-spinner'
import queryString from 'query-string'
import VideoRoom from '../components/VideoRoom';
import { OpenVidu } from 'openvidu-browser'

const Room = ({ history }) => {

    const OV = new OpenVidu();

    const { id } = useParams();
    const { s } = queryString.parse(history.location.search)

    const { userID, setUserID } = useContext(UserContext)

    const userTID = useRef(null)

    const [data, setData] = useState({
        image: "",
        live: false
    })

    const [loading, setLoading] = useState(true)
    const [retrievingData, setRetrievingData] = useState(false)

    const [userName, setUserName] = useState("")
    const [email, setEmail] = useState("")
    const [userTeam, setUserTeam] = useState("")

    const [password, setPassword] = useState("")
    const [passwordOK, setPasswordOK] = useState(false)
    const [wrongPassword, setWrongPassword] = useState(false)
    const [serverError, setServerError] = useState(false)
    const [role, setRole] = useState("SUBSCRIBER")

    const [token, setToken] = useState(undefined)

    const [confirmation, setConfirmation] = useState(false)


    useEffect(() => {

        userID && recoverUserTeam()
        s && setPassword(s)


        const listener = firestore().collection("rooms").doc(id).onSnapshot(doc => {
            if (doc.exists) {
                setData({ ...doc.data(), id: doc.id })
                setLoading(false)

            } else {
                alert("La sala a la que intentas acceder no existe")
            }
        })

        return () => listener()
    }, [])

    useEffect(() => {
        if (s || passwordOK) {
            (userID || userTID.current) && token === undefined && joinSession()
        }
    }, [data])


    const checkCamera = () => {

        OV.getDevices().then((media) => {
            // console.log(media)
            // alert(JSON.stringify(media))
            setRole("PUBLISHER")
            return "PUBLISHER"
        }).catch(err => {
            if (OV.checkSystemRequirements){
                // alert(`Navegador compatible a medias ${OV.checkSystemRequirements()}, ${OV.checkScreenSharingCapabilities()}, ${err}`)
                // console.log("SUBSCRIBER")
                return "SUBSCRIBER"
            } else {
                alert("Navegador no compatible")
            }
        })
        // if (isIOS) {
        //     navigator.getUserMedia({ audio: true, video : true }).then(res => {
        //         setRole("PUBLISHER")
        //         console.log("PUBLISHER")
        //         return "PUBLISHER"
        //     }).catch(res => {
        //         console.log("SUBSCRIBER")
        //         // webcam is not available
        //         return "SUBSCRIBER"
        //     })
        // } else {

        // if (navigator && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

        //     navigator.getUserMedia = (
        //         navigator.getUserMedia ||
        //         navigator.webkitGetUserMedia ||
        //         navigator.mozGetUserMedia ||
        //         navigator.msGetUserMedia
        //     );

        //     if (typeof navigator.mediaDevices.getUserMedia === 'undefined') {
        //         navigator.getUserMedia({
        //             audio: true,
        //             video: true,
        //         }, () => {
        //             setRole("PUBLISHER")
        //             return "PUBLISHER"
        //         }, () => {
        //             console.log("SUBSCRIBER")
        //             return "SUBSCRIBER"
        //         });
        //     } else {
        //         navigator.mediaDevices.getUserMedia({
        //             audio: true,
        //             video: {
        //                 width: { min: 1024, ideal: 1280, max: 1920 },
        //                 height: { min: 576, ideal: 720, max: 1080 }
        //             }
        //         }).then(() => {
        //             setRole("PUBLISHER")
        //             return "PUBLISHER"
        //         }).catch(() => {
        //             console.log("SUBSCRIBER")
        //             return "SUBSCRIBER"
        //         });
        //     }
        // } else {
        //     return "SUBSCRIBER"
        // }
    }

    // SIRVE PARA RECUPERAR NOMBRE Y APELLIDOS DE EL ID GUARDADO
    const recoverUserTeam = async () => {
        setUserName(atob(userID).split(",")[0])
        setUserTeam(atob(userID).split(",")[1])
    }

    // GUARDA UN USUARIO SI NO EXISTIESE
    const submitUserTeam = async () => {
        await localStorage.setItem("userName", userName)
        await localStorage.setItem("userTeam", userTeam)
        // await localStorage.setItem("email", email)
        encodeUserID(userName + "," + userTeam)
    }
    // console.log(userID)
    const encodeUserID = async (st) => {
        await setUserID(btoa(st))
        userTID.current = btoa(st)
        s && userTID.current && await joinSession()
    }

    const joinSession = () => {

        setRetrievingData(true)
        setWrongPassword(false)
        setServerError(false)

        if (!parseInt(password)) {

            setWrongPassword(true)
            setRetrievingData(false)
        } else {

            const role = checkCamera()

            const variables = { password: parseInt(password), sessionID: id, userID: userID || userTID.current, role: role }

            axios.post("https://europe-west1-duo-city.cloudfunctions.net/api/joinRoom", {
                method: 'POST',
                body: JSON.stringify(variables)
            }).then((response) => {
                // console.log('TOKEN', response.data)
                if (response.data.name === "Error") {
                    // TENEMOS QUE CONTROLAR ESTE ERROR
                    setRetrievingData(false)
                    return
                } else if (response.data === "Waiting") {
                    // NO HACE NADA SOLO ESTA ESPERANDO A SER APROBADO
                    setPasswordOK(true)
                    return
                } else if (response.data) {
                    // HAY UN TOKEN ASI QUE PARA DELANTE
                    setToken(response.data)
                    setPasswordOK(true)
                    setPassword("")
                } else {
                    // ALGO HA IDO MAL
                    !response.data && setWrongPassword(true)
                }
                setRetrievingData(false)
            }).catch((error) => {
                // LOS 403 Y 404 SE CONTROLAN AQUI
                // console.log(error.response)
                if (error && error.response && error.response.status === 403) {
                    setWrongPassword(true)
                } else if (error && error.response && (error.response.status === 404 || error.response.status === 405)) {
                    setServerError(true)
                } else {
                    setServerError(true)
                }
                setRetrievingData(false)
            })
        }

    }


    return (
        <Box flex="grow">
            {loading ?
                <Box />
                :
                data.ended ?
                    <Box>
                        <Box flex="grow" align="center" >
                            <RomHeader title={data.title} image={data.image} description={data.description} />
                            <Box align="center" style={{ zIndex: 2, width: "100%" }} pad="medium" margin={{ top: "-50px" }} width={{ max: "600px" }} gap="medium" background="white" elevation="medium" round="7px">
                                <Box gap="small" margin={{ vertical: "medium" }}>
                                    <Heading margin="none" textAlign="center" level="3">Esta sessión ya ha terminado</Heading>
                                    <Text textAlign="center">Esta sala estuvo activa el {moment(data.starts.toDate()).format("DD MMM")}</Text>
                                    <Box background="light-3" pad={{ horizontal: "small", vertical: "5px" }} round="small">
                                        <Heading level="3" margin="none" textAlign="center">{moment(data.starts.toDate()).format("HH:mm")} - {moment(data.ends.toDate()).format("HH:mm")}</Heading>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    :
                    !data.live ?
                        <Box>
                            <Box flex="grow" align="center" >
                                <RomHeader title={data.title} image={data.image} description={data.description} />
                                <Box align="center"
                                    // style={{ zIndex: 2, width: "100%" }} 
                                    pad="medium"
                                    // margin={{ top: "-50px" }} 
                                    width={{ max: "600px" }}
                                    gap="medium"
                                // background="white" 
                                // elevation="medium" 
                                // round="7px"
                                >
                                    {/* <Heading margin="none" textAlign="center" level="3">Sala no activa</Heading> */}
                                    <Box gap="small" margin={{ top: "medium" }} align="center">
                                        <Text textAlign="center">Esta sala está programada para el {moment(data.starts.toDate()).format("DD MMMM")}.<br />Espera a que el moderador inicie la sesión</Text>
                                    </Box>
                                    <Box background="dark-1" pad={{ horizontal: "small", vertical: "5px" }} round="small">
                                        <Heading level="3" margin="none" textAlign="center">{moment(data.starts.toDate()).format("HH:mm")} - {moment(data.ends.toDate()).format("HH:mm")}</Heading>
                                    </Box>
                                    <Box
                                    // margin={{ vertical: "large" }}
                                    >
                                        <Loader
                                            type="Watch"
                                            color="#002C50"
                                            height={80}
                                            width={80}
                                        />
                                    </Box>
                                </Box>
                                {/* <Text color="gray" textAlign="center" style={{ marginTop: 20, marginBottom: 30, cursor: "pointer" }} onClick={() => history.push(`/host/${id}`)}>Soy el Organizador</Text> */}
                            </Box>
                        </Box>
                        :
                        !userID ?
                            <Keyboard
                                onEnter={() => submitUserTeam()}
                            >
                                <Box align="center" >
                                    <RomHeader title={data.title} image={data.image} description={data.description} />
                                    {/* <Box style={{ zIndex: 2, width: "100%" }} pad="medium" margin={{ top: "-50px" }} width={{ max: "600px" }} gap="medium" background="white" elevation="medium" round="7px"> */}
                                    <Box
                                        // align="center"
                                        // style={{ zIndex: 2, width: "100%" }} 
                                        pad="medium"
                                        // margin={{ top: "-50px" }} 
                                        width={{ max: "600px" }}
                                        gap="medium"
                                        style={{ width: "100%" }}
                                    // background="white" 
                                    // elevation="medium" 
                                    // round="7px"
                                    >
                                        <Heading margin="none" textAlign="center" level="3">Introduce tus datos</Heading>
                                        <Box gap="small">
                                            <Text size="small">Nombre y Apellidos</Text>
                                            <TextInput style={{ fontSize: 16 }} placeholder="Tu nombre..." value={userName} onChange={(ev) => setUserName(ev.target.value)} />
                                        </Box>

                                        {/* <Box gap="small">
                                            <Text size="small">Email</Text>
                                            <TextInput style={{ fontSize: 16 }} placeholder="Tu email..." value={email} onChange={(ev) => setEmail(ev.target.value)} />
                                        </Box> */}

                                        <Box gap="small">
                                            <Text size="small">Club/Organización</Text>
                                            <TextInput style={{ fontSize: 16 }} placeholder="Escribe aquí..." value={userTeam} onChange={(ev) => setUserTeam(ev.target.value)} />
                                        </Box>

                                        <Box align="center">
                                            <Button
                                                primary
                                                disabled={userName === "" || userTeam === ""}
                                                onClick={() => submitUserTeam()}
                                                label={"Acceder"}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                {/* <Text color="gray" textAlign="center" style={{ marginTop: 20, marginBottom: 30, cursor: "pointer" }} onClick={() => history.push(`/host/${id}`)}>Soy el Organizador</Text> */}
                            </Keyboard>
                            :
                            data.waitingRoom && data.waitingRoom.includes(userID) && !data.granted.includes(userID) && passwordOK ?
                                <Box>
                                    <Box flex="grow" align="center" >
                                        <RomHeader title={data.title} image={data.image} description={data.description} />
                                        {/* <Box align="center" style={{ zIndex: 2, width: "100%" }} pad="medium" margin={{ top: "-50px" }} width={{ max: "600px" }} gap="medium" background="white" elevation="medium" round="7px"> */}
                                        <Box
                                            // align="center"
                                            // style={{ zIndex: 2, width: "100%" }} 
                                            pad="medium"
                                            // margin={{ top: "-50px" }} 
                                            width={{ max: "600px" }}
                                            gap="medium"
                                            style={{ width: "100%" }}
                                        // background="white" 
                                        // elevation="medium" 
                                        // round="7px"
                                        >
                                            <Box gap="small" margin={{ top: "medium" }}>
                                                <Heading level="3" margin="none" textAlign="center">Esperando a que el moderador te permita entrar a la sala</Heading>
                                            </Box>
                                            <Box align="center" margin={{ vertical: "large" }}>
                                                <Loader
                                                    type="TailSpin"
                                                    color="#002C50"
                                                    height={80}
                                                    width={80}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                :
                                data.banned.includes(userID) ?
                                    <Box>
                                        <Box flex="grow" align="center" >
                                            <RomHeader title={data.title} image={data.image} description={data.description} />
                                            {/* <Box align="center" style={{ zIndex: 2, width: "100%" }} pad="medium" margin={{ top: "-50px" }} width={{ max: "600px" }} gap="medium" background="white" elevation="medium" round="7px"> */}
                                            <Box
                                                // align="center"
                                                // style={{ zIndex: 2, width: "100%" }} 
                                                pad="medium"
                                                // margin={{ top: "-50px" }} 
                                                width={{ max: "600px" }}
                                                gap="medium"
                                                style={{ width: "100%" }}
                                            // background="white" 
                                            // elevation="medium" 
                                            // round="7px"
                                            >
                                                <Box gap="small" margin={{ top: "medium" }}>
                                                    <Heading level="3" margin="none" textAlign="center">Has sido expulsado de esta sala por el moderador</Heading>
                                                </Box>
                                                <Box align="center" margin={{ top: "medium", bottom: "large" }}>
                                                    <Button primary label="Salir" onClick={() => history.push("/")} />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    :
                                    !token ?
                                        <Keyboard onEnter={() => joinSession()} >
                                            <Box align="center" >
                                                <RomHeader title={data.title} image={data.image} description={data.description} />
                                                {/* <Box style={{ zIndex: 2, width: "100%" }} pad="medium" margin={{ top: "-50px" }} width={{ max: "600px" }} gap="medium" background="white" elevation="medium" round="7px"> */}
                                                <Box align="center"
                                                    // style={{ zIndex: 2, width: "100%" }} 
                                                    pad="medium"
                                                    // margin={{ top: "-50px" }} 
                                                    width={{ max: "600px" }}
                                                    style={{ height: "100%" }}
                                                    gap="medium"
                                                // background="white" 
                                                // elevation="medium" 
                                                // round="7px"
                                                >

                                                    {!retrievingData ?
                                                        <Box margin={{ top: "medium" }}>
                                                            <Heading textAlign="center" level="3">Introduce la Contraseña</Heading>

                                                            <Box gap="small">
                                                                {/* <Text size="small">Contraseña</Text> */}
                                                                <TextInput style={{ fontSize: 16 }} placeholder="Contraseña..." value={password} onChange={(ev) => setPassword(ev.target.value)} type="password" />

                                                                {wrongPassword && <Text color="status-critical" textAlign="center">La contraseña introducida no es correcta.</Text>}
                                                                {serverError && <Text color="status-critical" textAlign="center">Ha ocurrido un error al conectar con la sala.<br />Asegurate que tu moderador ha iniciado la sesión.</Text>}
                                                            </Box>
                                                            <Box align="center" style={{ marginTop: "20px" }}>
                                                                <Button
                                                                    primary
                                                                    disabled={userName === "" || userTeam === "" || password === "" || retrievingData}
                                                                    onClick={() => joinSession()}
                                                                    label={"Acceder"}
                                                                />
                                                            </Box>
                                                        </Box>
                                                        :
                                                        <Box height={{ min: "small" }} flex="grow" align="center" justify="center" gap="medium">
                                                            <Loader type="Puff" color="#002C50" height={60} width={60} />
                                                            <Text textAlign="center">Accediendo a la sala...</Text>
                                                        </Box>
                                                    }

                                                </Box>
                                            </Box>
                                            {/* <Text color="gray" textAlign="center" style={{ marginTop: 20, marginBottom: 30, cursor: "pointer" }} onClick={() => history.push(`/host/${id}`)}>Soy el Organizador</Text> */}
                                        </Keyboard>
                                        :
                                        <VideoRoom
                                            token={token}
                                            closeSessionCallback={() => {
                                                setToken(undefined)
                                                history.push("/")
                                            }}
                                            clientData={{ userName, userTeam, tok: userID }}
                                            id={id}
                                            data={data}
                                        />
            }

            {confirmation &&
                <Layer>
                    <Box background="white" pad="medium" round="small" elevation="large">
                        <Heading level="3" textAlign="center">¿Estás seguro que deseas cerrar esta sesión?</Heading>
                    </Box>
                </Layer>
            }
        </Box >
    );
}

export default Room;

const RomHeader = ({ title, image, description }) => {
    return (
        <Box style={{ position: "relative" }} width="100%" flex="grow" align="center" pad={{ top: "100px", bottom: "100px" }} background={`url('${image}')`}>
            <Box style={{ position: "absolute", top: 0 }} background="rgba(0,0,0,.8)" fill />
            <Heading style={{ zIndex: 2 }} color="white" margin="none" level="2">{title}</Heading>
            {/* <Box width={{ max: "800px" }} style={{ width: "100%", zIndex: 2 }}>
                <Text style={{ color: "white", marginBottom: 70, marginTop: 10 }} textAlign="center">{description}</Text>
            </Box> */}
        </Box>
    )
}