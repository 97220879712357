import React, { useState, useEffect } from 'react';
import { Box, Image, Text, Header as HeaderGrommet, TextInput, Button, Layer, Heading } from 'grommet';
import * as Icon from 'grommet-icons'
import Logo from "../assets/img/logo2.png"
import NewRoomRequest from './NewRoomRequest';
import { Link } from 'react-router-dom';


const logo = "https://files.ffmadrid.es/pnfg/img/web_responsive/ESP/nova_marco/logo2.png"


const Header = ({ authed, userID, changeUserCallback, showMenu }) => {

    const [open, toogle] = useState(false)
    const [newRoomModal, setNewRoomModal] = useState(false)
    const [userName, setUserName] = useState("")
    const [userTeam, setUserTeam] = useState("")
    const [openUser, setOpenUser] = useState(false)

    useEffect(() => {
        const recoverUserTeam = async () => {
            setUserName(atob(userID).split(",")[0])
            setUserTeam(atob(userID).split(",")[1])
        }

        userID && recoverUserTeam()
    }, [userID])



    const changeUserTeam = async () => {
        await localStorage.setItem("userName", userName)
        await localStorage.setItem("userTeam", userTeam)
        changeUserCallback(btoa(`${userName},${userTeam}`))
        setOpenUser(false)
    }


    return (
        <Box flex={showMenu ? "grow" : false} height={{max:'80px'}}>
            <HeaderGrommet background={{ color: "#DA1B1D", image:"url(https://files.ffmadrid.es/pnfg/img/web_responsive/ESP/entorno/fondo_cab.jpg)", position: "bottom" }} style={{ display: showMenu ? "flex" : "none" }} pad={{ horizontal: "medium" }} height="80px" fill="horizontal" justify="between" direction="row" elevation="medium">
                <Link style={{ textDecoration: "none" }} to="/">
                    <Box height="80px" width="200px" pad="small">
                        <Image fit="contain" src={logo} />
                    </Box>
                </Link>
                <Box direction="row" align="center">
                    {/* <Link style={{ textDecoration: "none" }} to="/login">
                        <Text>Home</Text>
                    </Link> */}
                    <Button onClick={() => setOpenUser(true)} icon={<Icon.User />} hoverIndicator />
                    {!authed && <Button onClick={() => toogle(true)} icon={<Icon.CircleQuestion />} hoverIndicator />}
                    {/* <Button onClick={() => setNewRoomModal(true)} icon={<Icon.Add />} hoverIndicator /> */}
                    {authed && <Button href="/admin" icon={<Icon.ShieldSecurity />} hoverIndicator />}
                    {authed && <Button href="/logout" icon={<Icon.Logout />} hoverIndicator />}
                </Box>
            </HeaderGrommet>

            {open &&
                <Layer
                    onClickOutside={() => toogle(false)}
                    onEsc={() => toogle(false)}
                    responsive={false}
                >
                    <Box width="large">
                        <Button hoverIndicator onClick={() => toogle(false)} style={{ position: "absolute", top: 0, right: 0 }} icon={<Icon.Close />} />
                        <Box gap="medium" background="white" round="small" pad="medium" >
                            <Heading margin="none" level="3">¿Cómo conectarse a una sala?</Heading>
                            <Box gap="small">
                                <Text>1 - Haz click la sala que quieres conectarte.</Text>
                                <Text>2 - Si es una sesión privada introduce la contraseña.</Text>
                                <Text>3 - Permite a la aplicación a acceder a la cámara y al micrófono.</Text>
                            </Box>
                            {/* <Box align="center" gap="small">
                                <Heading style={{ width: "100%" }} margin="none" level="4">También puedes consultar este video tutorial</Heading>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/oq1AN1OLIrA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </Box> */}
                        </Box>
                    </Box>
                </Layer>
            }

            {newRoomModal &&
                <NewRoomRequest
                    closeCallback={() => {
                        setNewRoomModal(false)
                    }} />
            }
            {openUser &&
                <Layer
                    onClickOutside={() => setOpenUser(false)}
                    onEsc={() => setOpenUser(false)}
                    responsive={false}
                >
                    <Box flex="grow" align="center" >
                        <Box style={{ zIndex: 2, width: "100%" }} pad="medium" margin={{ top: "-50px" }} width={{ max: "600px" }} gap="medium" background="white" elevation="medium" round="7px">
                            <Heading margin="none" level="3">Introduce tus datos</Heading>
                            <Text>Aquí podrás cambiar tu nombre e información que aparecerá en las reuniones.</Text>
                            <Box gap="small">
                                <Text size="small">Nombre y Apellidos</Text>
                                <TextInput style={{ fontSize: 16 }} placeholder="Tu nombre..." value={userName} onChange={(ev) => setUserName(ev.target.value)} />
                            </Box>

                            <Box gap="small">
                                <Text size="small">Club/Organización</Text>
                                <TextInput style={{ fontSize: 16 }} placeholder="Escribe aquí..." value={userTeam} onChange={(ev) => setUserTeam(ev.target.value)} />
                            </Box>

                            <Box align="center" direction="row" justify="between" gap="small">
                                <Button
                                    primary
                                    color="light-2"
                                    onClick={() => setOpenUser(false)}
                                    label={"Cancelar"}
                                />
                                <Button
                                    primary
                                    disabled={userName === "" || userTeam === ""}
                                    onClick={() => changeUserTeam()}
                                    label={"Modificar"}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Layer>
            }
        </Box>
    );
}

export default Header;